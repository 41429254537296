jQuery(document).ready(function($) {

  // clear and restore form input values
  var el = $('input[type=text], input[type=email], textarea, input.header-search');
  el.focus(function(e) {
    if (e.target.value === e.target.defaultValue)
      e.target.value = '';
  });
  el.blur(function(e) {
    if (e.target.value === '')
      e.target.value = e.target.defaultValue;
  });

  // remove hash from url on refresh
  location.hash = ""

  // replace logo url if no svg support
  if (!Modernizr.svg) {
    $(".branding .logo").css("background", "url(../images/logo-header.svg)");
  }

  //even height boxes (for block-grids)
  // equalheight = function(container) {

  //   var currentTallest = 0,
  //   currentRowStart = 0,
  //   rowDivs = new Array(),
  //   $el,
  //   topPosition = 0;
  //   $(container).each(function() {

  //     $el = $(this);
  //     $($el).height('auto')
  //     topPostion = $el.position().top;

  //     if (currentRowStart != topPostion) {
  //       for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
  //         rowDivs[currentDiv].height(currentTallest);
  //       }
  //             rowDivs.length = 0; // empty the array
  //             currentRowStart = topPostion;
  //             currentTallest = $el.height();
  //             rowDivs.push($el);
  //           } else {
  //             rowDivs.push($el);
  //             currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  //           }
  //           for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
  //             rowDivs[currentDiv].height(currentTallest);
  //           }
  //         });
  // }
  // equal height latest blog posts
  // $(window).load(function() {
  //     equalheight('.equal-height');
  // });
  // $(window).resize(function() {
  //     $('.equal-height').attr('style', '');
  // });

  // open and close property search
  $('#open-search, #close-search').removeAttr('href');
  $('#open-search, #close-search').click(function() {
    $('#prop_search').toggleClass('open');
    $('#prop_search > .row').slideToggle('open');
  })

  // enquire
  enquire
    .register("screen and (max-width: 40em)", function() {
      // remove equal height on property archives
      // $(window).resize(function() {
      //   $('.equal-height').attr('style', '');
      // });
      // remove equal height on general items
      // $(window).resize(function() {
      //   $('.prop-equal-height').attr('style', '');
      // });
    })
    .register("screen and (min-width: 40.063em)", function() {
      // remove open class from mobile search
      $('#prop_search').removeClass('open');
      $('#prop_search > .row').attr('style', '');
    })
    .register("screen and (min-width: 0) and (max-width: 64em)", function() {
      // remove default event
      $('#menu-primary-nav > li.menu-item-has-children > a').click(function(event) {
          $(this).removeAttr('href');
        })
        // open sub-menu items on mobile
      $('#menu-primary-nav > li.menu-item-has-children > a').click(function(event) {
        $(this).toggleClass('open');
        $(this).next('ul').slideToggle();
        event.stopPropagation();
      });
    })
    .register("screen and (min-width: 64.063em)", function() {
      // remove default event
      $('#menu-primary-nav > li.menu-item-has-children > a').click(function(event) {
          $(this).removeAttr('href');
        })
        // open sub-menu items on desktop
      $('#menu-primary-nav > li.menu-item-has-children').hoverIntent(function() {
        if ($('ul:first', this).length > 0) {
          $('a', this).addClass('open');
          $('ul:first', this).stop().slideDown();
        }
      }, function() {
        $('ul:first', this).stop().slideUp(function() {
          $(this).prev('a').removeClass('open');
        })
      });
    })
    .register("screen and (min-width: 64.063em)", function() {
      // equal height for general items
      // $(window).load(function() {
      //   equalheight('.equal-height');
      // });
      // $(window).resize(function() {
      //   equalheight('.equal-height');
      // });
      // equal height for property archives
      // $(window).load(function() {
      //   equalheight('.prop-equal-height');
      // });
      // $(window).resize(function() {
      //   equalheight('.prop-equal-height');
      // });
    });

  // mobile primary nav button animation
  $('.btn-mobile-nav-primary').click(function() {
    $(this).toggleClass('open');
    $('.nav-primary').slideToggle();
    $(this).find('.bar:nth-of-type(1)').toggleClass('rotate-left');
    $(this).find('.bar:nth-of-type(2)').toggleClass('fade');
    $(this).find('.bar:nth-of-type(3)').toggleClass('rotate-right');
  });

  // open mobile nav
  $('#nav-open, #nav-close, #nav-close.mobile').click(function() {
    $('#wrapper-out').toggleClass('open');
    menu - primary - nav
  })

  // swap out county selects and reset
  $('select#state').on('change', function() {
    if ($(this).val() == 'illinois') {

      $('select#ohio_county').find('option').removeAttr('selected');
      $('select#no_county, select#ohio_county').addClass('hide');

      $('select#illinois_county').removeClass('hide');

    } else if ($(this).val() == 'ohio') {

      $('select#illinois_county').find('option').removeAttr('selected');
      $('select#no_county, select#illinois_county').addClass('hide');

      $('select#ohio_county').removeClass('hide');

    } else {
      $('select#illinois_county, select#ohio_county').find('option').removeAttr('selected').addClass('hide');
      $('select#no_county').removeClass('hide');
    }
  });

  // prevent get of empty search inputs
  $("#prop_search").submit(function() {
    $('select').each(function() {
      if ($(this).val() == "") {
        $(this).prop('disabled', true);
      }
    });
  });

  // iOS6 Cycle2 Swipe Fix
  if (/(iPhone|iPad|iPod)\sOS\s6/.test(navigator.userAgent)) {
    (function(window) {
      // This library re-implements setTimeout, setInterval, clearTimeout, clearInterval for iOS6.
      // iOS6 suffers from a bug that kills timers that are created while a page is scrolling.
      // This library fixes that problem by recreating timers after scrolling finishes (with interval correction).
      // This code is free to use by anyone (MIT, blabla).
      // Original Author: rkorving@wizcorp.jp
      var timeouts = {};
      var intervals = {};
      var orgSetTimeout = window.setTimeout;
      var orgSetInterval = window.setInterval;
      var orgClearTimeout = window.clearTimeout;
      var orgClearInterval = window.clearInterval;
      // To prevent errors if loaded on older IE.
      if (!window.addEventListener) return false;

      function createTimer(set, map, args) {
        var id, cb = args[0],
          repeat = (set === orgSetInterval);

        function callback() {
          if (cb) {
            cb.apply(window, arguments);
            if (!repeat) {
              delete map[id];
              cb = null;
            }
          }
        }
        args[0] = callback;
        id = set.apply(window, args);
        map[id] = {
          args: args,
          created: Date.now(),
          cb: cb,
          id: id
        };
        return id;
      }

      function resetTimer(set, clear, map, virtualId, correctInterval) {
        var timer = map[virtualId];
        if (!timer) {
          return;
        }
        var repeat = (set === orgSetInterval);
        // cleanup
        clear(timer.id);
        // reduce the interval (arg 1 in the args array)
        if (!repeat) {
          var interval = timer.args[1];
          var reduction = Date.now() - timer.created;
          if (reduction < 0) {
            reduction = 0;
          }
          interval -= reduction;
          if (interval < 0) {
            interval = 0;
          }
          timer.args[1] = interval;
        }
        // recreate
        function callback() {
          if (timer.cb) {
            timer.cb.apply(window, arguments);
            if (!repeat) {
              delete map[virtualId];
              timer.cb = null;
            }
          }
        }
        timer.args[0] = callback;
        timer.created = Date.now();
        timer.id = set.apply(window, timer.args);
      }
      window.setTimeout = function() {
        return createTimer(orgSetTimeout, timeouts, arguments);
      };
      window.setInterval = function() {
        return createTimer(orgSetInterval, intervals, arguments);
      };
      window.clearTimeout = function(id) {
        var timer = timeouts[id];
        if (timer) {
          delete timeouts[id];
          orgClearTimeout(timer.id);
        }
      };
      window.clearInterval = function(id) {
        var timer = intervals[id];
        if (timer) {
          delete intervals[id];
          orgClearInterval(timer.id);
        }
      };
      //check and add listener on the top window if loaded on frameset/iframe
      var win = window;
      while (win.location != win.parent.location) {
        win = win.parent;
      }
      win.addEventListener('scroll', function() {
        // recreate the timers using adjusted intervals
        // we cannot know how long the scroll-freeze lasted, so we cannot take that into account
        var virtualId;
        for (virtualId in timeouts) {
          resetTimer(orgSetTimeout, orgClearTimeout, timeouts, virtualId);
        }
        for (virtualId in intervals) {
          resetTimer(orgSetInterval, orgClearInterval, intervals, virtualId);
        }
      });
    }(window));
  }

  // cycle2 and clearing
  // $(document.body).on("open.fndtn.clearing", function(event) {
  //     $('[data-clearing] > li').addClass('clearing-active');
  //     $('[data-clearing] > li > img').addClass('clearing-visible');
  // });
  // $(document.body).on("close.fndtn.clearing", function(event) {
  //     $('[data-clearing] > li').removeClass('clearing-active');
  //     $('[data-clearing] > li > img').removeClass('clearing-visible');
  // });

  // aerial gallery
  var aerialsSlideshows = $('#aerials .cycle-slideshow').on('cycle-next cycle-prev', function(e, opts) {
    aerialsSlideshows.not(this).cycle('goto', opts.currSlide);
  });
  // forward slideshow when clicking thumbnail image
  $('#aerials .cycle-slide').click(function() {
    var index = $('#aerials-carousel').data('cycle.API').getSlideIndex(this);
    aerialsSlideshows.cycle('goto', index);
  });
  // foward when click img
  $('#aerials-slideshow .cycle-slide').on('click', function(e) {
    e.preventDefault();
    $('#aerials').find('a.cycle-next.bottom').trigger('click');
  });
  // open clearing gallery link
  $('#aerials .open-swipebox').on('click', function(e) {
    e.preventDefault();
    $('.aerials-gallery a:first').trigger('click');
  });

  // property gallery
  var propertySlideshows = $('#property .cycle-slideshow').on('cycle-next cycle-prev', function(e, opts) {
    propertySlideshows.not(this).cycle('goto', opts.currSlide);
  });
  // forward slideshow when clicking thumbnail image
  $('#property .cycle-slide').click(function() {
    var index = $('#property-carousel').data('cycle.API').getSlideIndex(this);
    propertySlideshows.cycle('goto', index);
  });
  // foward when click img
  $('#property-slideshow .cycle-slide').on('click', function(e) {
    e.preventDefault();
    $('#property').find('a.cycle-next.bottom').trigger('click');
  });
  // open clearing gallery link
  $('#property .open-swipebox').on('click', function(e) {
    e.preventDefault();
    $('.property-gallery a:first').trigger('click');
  });

  // lodging gallery
  var lodgingSlideshows = $('#lodging .cycle-slideshow').on('cycle-next cycle-prev', function(e, opts) {
    lodgingSlideshows.not(this).cycle('goto', opts.currSlide);
  });
  // forward slideshow when clicking thumbnail image
  $('#lodging .cycle-slide').click(function() {
    var index = $('#lodging-carousel').data('cycle.API').getSlideIndex(this);
    lodgingSlideshows.cycle('goto', index);
  });
  // foward when click img
  $('#lodging-slideshow .cycle-slide').on('click', function(e) {
    e.preventDefault();
    $('#lodging').find('a.cycle-next.bottom').trigger('click');
  });
  // open clearing gallery link
  $('#lodging .open-swipebox').on('click', function(e) {
    e.preventDefault();
    $('.lodging-gallery a:first').trigger('click');
  });

  // wildlife gallery
  var wildlifeSlideshows = $('#wildlife .cycle-slideshow').on('cycle-next cycle-prev', function(e, opts) {
    wildlifeSlideshows.not(this).cycle('goto', opts.currSlide);
  });
  // forward slideshow when clicking thumbnail image
  $('#wildlife .cycle-slide').click(function() {
    var index = $('#wildlife-carousel').data('cycle.API').getSlideIndex(this);
    wildlifeSlideshows.cycle('goto', index);
  });
  // foward when click img
  $('#wildlife-slideshow .cycle-slide').on('click', function(e) {
    e.preventDefault();
    $('#wildlife').find('a.cycle-next.bottom').trigger('click');
  });
  // open clearing gallery link
  $('#wildlife .open-swipebox').on('click', function(e) {
    e.preventDefault();
    $('.wildlife-gallery a:first').trigger('click');
  });

  // videos
  var iframes = $('.iframe-container iframe');
  var status = $('.status');
  var videoSlideshows = $('#video .cycle-slideshow').on('cycle-next cycle-prev', function(e, opts) {
    videoSlideshows.not(this).cycle('goto', opts.currSlide);
  });
  // forward slideshow when clicking thumbnail image
  $('#video .cycle-slide').click(function() {
    var index = $('#video-carousel').data('cycle.API').getSlideIndex(this);
    videoSlideshows.cycle('goto', index);
    iframes.each(function() {
      var player=$f(this);
      player.api("pause");
    });
    return false;
  });
  // // foward when click img
  // $('#video-slideshow .cycle-slide').on('click', function(e) {
  //   e.preventDefault();
  //   $('#video').find('a.cycle-next.bottom').trigger('click');
  // });

  // control slides from keyboard
  // if ($('#aerials-carousel').is(':visible')) {
  //   function aerialKeyUp(e) {
  //     if (e.keyCode == 39) {
  //       $('#aerials').find('a.aerials.cycle-next.bottom').trigger('click');
  //     }
  //     if (e.keyCode == 37) {
  //       $('#aerials').find('a.aerials.cycle-prev.bottom').trigger('click');
  //     }
  //   }
  //   $(document).keyup(aerialKeyUp);
  // }

  // if ($('#property-carousel').is(':visible')) {
  //   function propertyKeyUp(e) {
  //     if (e.keyCode == 39) {
  //       $('#property').find('a.property.cycle-next.bottom').trigger('click');
  //     }
  //     if (e.keyCode == 37) {
  //       $('#property').find('a.property.cycle-prev.bottom').trigger('click');
  //     }
  //   }
  //   $(document).keyup(propertyKeyUp);
  // }

  // if ($('#lodging-carousel').is(':visible')) {
  //   function lodgingKeyUp(e) {
  //     if (e.keyCode == 39) {
  //       $('#lodging').find('a.lodging.cycle-next.bottom').trigger('click');
  //     }
  //     if (e.keyCode == 37) {
  //       $('#lodging').find('a.lodging.cycle-prev.bottom').trigger('click');
  //     }
  //   }
  //   $(document).keyup(lodgingKeyUp);
  // }

  // if ($('#wildlife-carousel').is(':visible')) {
  //   function wildlifeKeyUp(e) {
  //     if (e.keyCode == 39) {
  //       $('#wildlife').find('a.wildlife.cycle-next.bottom').trigger('click');
  //     }
  //     if (e.keyCode == 37) {
  //       $('#wildlife').find('a.wildlife.cycle-prev.bottom').trigger('click');
  //     }
  //   }
  //   $(document).keyup(wildlifeKeyUp);
  // }

  // if ($('#video-carousel').is(':visible')) {
  //   function videoKeyUp(e) {
  //     if (e.keyCode == 39) {
  //       $('#video').find('a.video.cycle-next.bottom').trigger('click');
  //     }
  //     if (e.keyCode == 37) {
  //       $('#video').find('a.video.cycle-prev.bottom').trigger('click');
  //     }
  //   }
  //   $(document).keyup(videoKeyUp);
  // }

  // cycle slides using keyboard controls
  // $('.resp-tabs-list li').mouseup(function() {
  //   setTimeout(function() {
  //     if ($('#aerials-carousel').is(':visible')) {
  //       console.log('aerials visible');
  //       $(document).unbind("keyup", propertyKeyUp);
  //       $(document).unbind("keyup", lodgingKeyUp);
  //       $(document).unbind("keyup", wildlifeKeyUp);
  //       $(document).unbind("keyup", videoKeyUp);

  //       $(document).keyup(aerialKeyUp);
  //     }

  //     if ($('#property-carousel').is(':visible')) {
  //       console.log('property visible');
  //       $(document).unbind("keyup", aerialKeyUp);
  //       $(document).unbind("keyup", lodgingKeyUp);
  //       $(document).unbind("keyup", wildlifeKeyUp);
  //       $(document).unbind("keyup", videoKeyUp);

  //       $(document).keyup(propertyKeyUp);
  //     }

  //     if ($('#lodging-carousel').is(':visible')) {
  //       $(document).unbind("keyup", aerialKeyUp);
  //       $(document).unbind("keyup", propertyKeyUp);
  //       $(document).unbind("keyup", wildlifeKeyUp);
  //       $(document).unbind("keyup", videoKeyUp);

  //       $(document).keyup(lodgingKeyUp);
  //     }

  //     if ($('#wildlife-carousel').is(':visible')) {
  //       $(document).unbind("keyup", aerialKeyUp);
  //       $(document).unbind("keyup", propertyKeyUp);
  //       $(document).unbind("keyup", lodgingKeyUp);
  //       $(document).unbind("keyup", videoKeyUp);

  //       $(document).keyup(wildlifeKeyUp);
  //     }

  //     if ($('#video-carousel').is(':visible')) {
  //       $(document).unbind("keyup", aerialKeyUp);
  //       $(document).unbind("keyup", propertyKeyUp);
  //       $(document).unbind("keyup", lodgingKeyUp);
  //       $(document).unbind("keyup", wildlifeKeyUp);

  //       $(document).keyup(videoKeyUp);
  //     }
  //   }, 100);
  // });

});
